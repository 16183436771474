import './HomePage.scss'
import cardImage1 from '../../assets/images/1-logo.png'
import cardImage2 from '../../assets/images/2-logo.png'
import cardImage3 from '../../assets/images/3-logo.png'
import HomeCard from "../../components/HomeCard";
import Footer from "../../containers/Footer";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import AOS from "aos";
import {Helmet} from "react-helmet";

const HomePage = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);
    const {t} = useTranslation()

    const CardData = [
        {
            imgLink: cardImage1,
            title: t("homePage.cardSection.0.title"),
            overview: t("homePage.cardSection.0.subtitle")
        },
        {
            imgLink: cardImage2,
            title: t("homePage.cardSection.1.title"),
            overview: t("homePage.cardSection.1.subtitle")
        },
        {
            imgLink: cardImage3,
            title: t("homePage.cardSection.2.title"),
            overview: t("homePage.cardSection.2.subtitle")
        }
    ]

    return (
        <>
            <Helmet>
                <title>
                    Home Page
                </title>
            </Helmet>
            <section className="intro">
                <div className="container">
                    <h1>Welcome to Ideal World Tour</h1>
                </div>
            </section>
            <section className="card-section">
                <div className="container">
                    <ul className="cards">
                        {
                            CardData.map(item => (
                                <HomeCard
                                    imgLink={item.imgLink}
                                    title={item.title}
                                    overview={item.overview}
                                />
                            ))
                        }
                    </ul>
                </div>
            </section>
            <section className="location">
                <div className="container">
                    <iframe
                        title='location'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47980.59092705225!2d69.16327330207683!3d41.26997485644368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8a6add9db721%3A0x7a27c4f09ef4001c!2sChilonzor%20District%2C%20Tashkent%2C%20Tashkent%20Region%2C%20Uzbekistan!5e0!3m2!1sen!2s!4v1726313131244!5m2!1sen!2s"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"/>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default HomePage
