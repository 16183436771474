import './Loader.scss'

const Loader = () =>{
    return(
        <>
            <div className="container-loader">
                <div className="loader"/>
            </div>
        </>
    )
}

export default Loader