import './SinglePost.scss'
import {useParams} from "react-router-dom";
import Footer from "../../containers/Footer";
import turk1 from "../../assets/images/turk-1.jpg";
import turk2 from "../../assets/images/turk-2.jpg";
import turk3 from "../../assets/images/turk-3.jpg";
import turk4 from "../../assets/images/turk-4.webp";
import dubai1 from "../../assets/images/dubai-1.jpg"
import dubai2 from "../../assets/images/dubai-2.jpg"
import dubai3 from "../../assets/images/dubai-3.webp"
import dubai4 from "../../assets/images/dubai-4.jpg"
import japan1 from "../../assets/images/japan-1.jpg"
import japan2 from "../../assets/images/japan-2.jpg"
import japan3 from "../../assets/images/japan-3.jpg"
import japan4 from "../../assets/images/japan-4.webp"
import europe1 from "../../assets/images/europe-1.jpg"
import europe2 from "../../assets/images/europe-2.jpg"
import europe3 from "../../assets/images/europe-3.jpg"
import europe4 from "../../assets/images/europe-4.jpg"
import british1 from "../../assets/images/british-1.jpg"
import british2 from "../../assets/images/british-2.jpg"
import british3 from "../../assets/images/british-3.jpg"
import british4 from "../../assets/images/british-4.jpg"
import georgia1 from "../../assets/images/beorgia-1.jpg"
import georgia2 from "../../assets/images/beorgia-2.jpg"
import georgia3 from "../../assets/images/beorgia-3.jpg"
import georgia4 from "../../assets/images/beorgia-4.jpg"
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const SinglePost = () => {

    const {id} = useParams()
    const {t} = useTranslation()

    function findArrayElementByTitle(array, idNumber) {
        return array.find((element) => {
            return element.id === idNumber;
        })
    }

    const constructionInfo = [
        {
            id: 1,
            title: t("blogPage.constructionCard.0.title"),
            fullyInfo: t("blogPage.constructionCard.0.fullyInfo"),
            arrayImg: [turk1, turk2, turk3, turk4]
        },

        {
            id: 2,
            title: t("blogPage.constructionCard.1.title"),
            fullyInfo: t("blogPage.constructionCard.1.fullyInfo"),
            arrayImg: [dubai1, dubai2, dubai3, dubai4]
        },
        {
            id: 3,
            title: t("blogPage.constructionCard.2.title"),
            fullyInfo: t("blogPage.constructionCard.2.fullyInfo"),
            arrayImg: [japan1, japan2, japan3, japan4]
        },
        {
            id: 4,
            title: t("blogPage.constructionCard.3.title"),
            fullyInfo: t("blogPage.constructionCard.3.fullyInfo"),
            arrayImg: [europe1, europe2, europe3, europe4]
        },
        {
            id: 5,
            title: t("blogPage.constructionCard.4.title"),
            fullyInfo: t("blogPage.constructionCard.4.fullyInfo"),
            arrayImg: [british1, british2, british3, british4]
        },
        {
            id: 6,
            title: t("blogPage.constructionCard.5.title"),
            fullyInfo: t("blogPage.constructionCard.5.fullyInfo"),
            arrayImg: [georgia1, georgia2, georgia3, georgia4]
        }
    ]

    let construction = findArrayElementByTitle(constructionInfo, parseInt(id))

    console.log(construction)

    return (
        <>
            <Helmet>
                <title>
                    Single post
                </title>
            </Helmet>
            <section className="construction-info">
                <div className="container">
                    <div className="img-construction">
                        {
                            construction.arrayImg.map(item => (
                                <img src={item} alt=""/>
                            ))
                        }
                    </div>
                    <div className="info-construction">
                        <h1>{construction.title}</h1>
                        <p>{construction.fullyInfo}</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default SinglePost
